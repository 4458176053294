import React from 'react'

import { classSortByDate } from '~/libs/es/helper-class'

import ClassListItem from 'components/class-list-item'

export default function ({ items }) {
    return <>
        {
            items
                .slice()
                .sort(classSortByDate)
                .map(each => {
                    return <ClassListItem key={each.context.id} item={each} />
                })
        }
    </>
}